<template>
  <div class="wrapper-box">
    <el-card>
      <el-row>
        <el-col> </el-col>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
          ><i class="el-icon-refresh-right"></i> 刷新</el-button
        >
        <el-button type="primary" size="small" class="vd_export" @click="oneClickApproval()"
          ><i class="el-icon-s-order"></i> 一键审批</el-button
        >
      </el-row>
      <el-table ref="multiTable" :data="apprNeedData" border v-loading="loading" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="48" align="center" />
        <el-table-column label="单据编号" prop="form_no" width="120px" align="center" />
        <el-table-column label="审批模块" prop="perm_name" :show-overflow-tooltip="true" />
        <el-table-column label="发起人说明" prop="appr_inst_info" :show-overflow-tooltip="true" />
        <el-table-column label="审批时间" prop="create_time" :formatter="formatDate" width="280px" />
        <el-table-column label="操作" align="center" width="120px">
          <template slot-scope="scope">
            <el-button type="text" size="small" icon="el-icon-right" @click="goAppr(scope.row.appr_inst_url)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="审批" :visible.sync="dialogVisible" width="50%">
        <div class="vd_head_button">
          <el-button size="small" :disabled="!isEffect" type="success" @click="passAppr()">通过</el-button>
          <el-button size="small" :disabled="!isEffect" type="danger" @click="redAppr()">拒绝</el-button>
        </div>
        <el-row v-if="!isEffect && stffFlag" class="vg_mt_24">
          <div class="vd_dis_flex">
            <el-row>
              <el-col :md="4">
                <span>选择审批人：</span>
              </el-col>
              <el-col :md="20">
                <el-checkbox-group v-model="checkStffIds">
                  <el-checkbox v-for="item in checkList" :label="item.user_id" :key="item.user_id"
                    >{{ item.stff_name }}({{ item.dept_name }})</el-checkbox
                  >
                </el-checkbox-group>
              </el-col>
            </el-row>
          </div>
        </el-row>
        <el-row v-if="!isEffect" class="vg_mt_24">
          <!-- v-if="purchaseFlag === 0" -->
          <!-- <div class="vd_dis_flex"> -->
          <el-row>
            <el-col :md="4">
              <span> 备注：</span>
            </el-col>
            <el-col :md="20">
              <el-input type="textarea" :rows="2" placeholder="请填写原因" v-model="textarea"></el-input>
            </el-col>
          </el-row>
          <!-- </div> -->
          <!-- <div v-if="purchaseFlag === 1">
            <el-row v-if="btnCont === '通过'">
              <el-col :md="24">
                <span> 备注：</span>
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea"></el-input>
              </el-col>
            </el-row>
            <el-row v-if="btnCont === '拒绝'">
              <el-col :md="24">
                <span> 拒绝原因：</span>
                <el-select size="small" v-model="purchaseChoose" multiple placeholder="请选择">
                  <el-option
                    v-for="item in purchaeList"
                    :key="item.id"
                    :label="item.reason"
                    :value="item.reason">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </div> -->
        </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" :disabled="isEffect" @click="dialogVisible = false">取消</el-button>
          <el-button size="small" :disabled="isEffect" type="primary" @click="sublit">确认</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { apprAPI } from '@api/modules/appr';
export default {
  name: 'boardAllLeft',
  data() {
    return {
      apprNeedData: [],
      loading: true,
      multiSelection: [],
      purchaseFlag: [],
      dialogVisible: false,
      checkStffIds: [],
      checkList: [],
      isEffect: true,
      apprForm: {},
      textarea: '',
      apprFormB: {},
      stffFlag: true
    };
  },
  created() {
    if (Number(this.$route.query.perm_id) === 112 && this.$cookies.get('userInfo').dept_name === '采购一部') {
      this.purchaseFlag = 1;
    }
    this.initData();
  },
  methods: {
    initData() {
      this.getAllApprove();
    },
    // 获取待审批记录（全部）
    getAllApprove() {
      get(apprAPI.getIApprove, { limit: 100 })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.apprNeedData = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取所有信息
    getApprObj() {
      get(apprAPI.getApprInsts, { perm_id: this.multiSelection[0].perm_id, form_id: this.multiSelection[0].form_id })
        .then(res => {
          if (res.data.code === 0) {
            this.apprForm = res.data.data.next;
            // this.getStffList()
          } else {
            let mg = res.data.msg;
            let err = 'error';
            this.$message({ type: err, message: mg });
          }
        })
        .catch(() => {});
    },
    // 获取审批人
    getStffList() {
      const nodeBody = {};
      nodeBody.perm_id = this.apprForm.perm_id;
      nodeBody.form_id = this.apprForm.form_id;
      nodeBody.appr_inst_this_node = this.apprForm.appr_inst_this_node;
      nodeBody.appr_inst_action = this.apprForm.appr_inst_action;
      get(apprAPI.getApprInstNextNode, nodeBody)
        .then(res => {
          if (res.data.code === 0) {
            this.apprFormB = res.data.data;
            this.checkList = this.apprFormB.appr_node_user_list;
            if (this.apprFormB.appr_node_id === 0 || this.apprFormB.appr_node_id === -1) {
              this.stffFlag = false;
            }
          } else {
            let mg = res.data.msg;
            this.isSelPanel = false;
            this.msgContent = mg;
          }
        })
        .catch(() => {});
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    goAppr(val) {
      this.jump(val);
    },
    buttonRefresh() {
      this.getAllApprove();
    },
    // 通过
    passAppr() {
      this.isEffect = false;
      this.apprForm.appr_inst_this_node = this.multiSelection[0].appr_inst_this_node;
      this.apprForm.appr_inst_action = 2;
      this.btnCont = '通过';
      this.getStffList();
    },
    // 拒绝
    redAppr() {
      this.isEffect = false;
      this.btnCont = '拒绝';
      this.apprForm.appr_inst_this_node = this.multiSelection[0].appr_inst_this_node;
      this.apprForm.appr_inst_action = 3;
      this.getStffList();
    },
    // 一键审批
    oneClickApproval() {
      if (this.multiSelection.length === 0) return this.$message.warning('至少选择一条数据');
      let approvalFlag = true;
      this.multiSelection.forEach((item, index) => {
        if (index !== 0) {
          if (item.perm_id !== this.multiSelection[0].perm_id) {
            approvalFlag = false;
          }
          if (item.appr_inst_this_node !== this.multiSelection[0].appr_inst_this_node) {
            approvalFlag = false;
          }
        }
      });
      if (!approvalFlag) return this.$message.warning('所选数据必须为同一模块同一节点');
      this.getApprObj();
      this.dialogVisible = true;
    },
    // 提交
    sublit() {
      let temp = [];
      this.multiSelection.forEach((item, index) => {
        temp.push(item.form_id);
      });
      this.apprForm.appr_inst_remark = this.textarea;
      this.apprForm.user_ids = this.checkStffIds.join(',');
      this.apprForm.appr_inst_next_node = this.apprFormB.appr_node_id;
      this.apprForm.form_ids = temp.join(',');
      post(apprAPI.approveByIds, this.apprForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message.success('成功');
            this.dialogVisible = false;
            this.checkStffIds = [];
            this.checkList = [];
            this.isEffect = true;
            this.textarea = '';
            this.initData();
          } else {
            let msg = res.data.msg;
            let q = 'error';
            this.$message({ type: q, message: msg });
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped>
.el-table {
  margin-top: 10px;
}
.vd_dis_flex {
  display: flex;
}
</style>
